.RegisterUser{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  padding-top: 0;
}

.RegisterUser__form{
  max-width: 40rem;
  width: 70%;
  margin-top: 2rem;
}

/* #captcha{
  min-height: 100px;
} */
.ublcrnsubheader{
  font-size: 14px;
  font-weight: bold;
  padding-top: 10px;
}
.ublcrndetail{
  font-size: 12px;
  padding-top: 5px;
}
.ublcrndetail b{
  font-size: 12px;
  font-weight: bold;
}
.ublcrnpoweredby{
  align-self: flex-end;
  margin: 1rem 0;
  text-align: right;
  font-size: 10px;
  /* font-family: arial, helvetica, sans-serif; */
}

@media screen and (min-width: 750px){
  .dynamic_form__input__input{
    min-width: 320px;
    width: 15em;
  }
}