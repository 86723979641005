#searchSection{
  flex-wrap: wrap;
  justify-content: center;
}

#searchSection > .titleSection{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.checkBoxFields{
  display: flex;
  align-items: center;
  min-width: 300px;
  width: 50%;
  justify-content: space-around;
}

.algolia__search__container {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 1.5em;
}

.algolia__hits {
  background-color: var(--clr-background);
  height: 8em;
  overflow-y: auto;
  margin-top: .5em;
}

.ais-SearchBox {
  width: 50%;
  margin: 1em auto;
}

.ais-SearchBox-input {
  width: calc(100% - 3em);
  /* height: 4em; */
  font-size: 2em;
  margin-right: .5em;
}

.ais-SearchBox-submitIcon,
.ais-SearchBox-resetIcon {
  width: 1.5em;
  height: 1.5em;
  margin-right: .25em;
}

.expandatableTable__container {
  margin-bottom: 1em;
}