.family{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: 90vh;
}

.familyContainer{
  width: 100%;
  padding: 1rem;
  font-size: 16px !important;
}