.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.systemContainer{
  /* background-color: var(--clr-grey); */
  /* border-radius: 1rem; */
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.systemContainer > div {
  min-width: 300px;
  width: 80%;
}

.systemContainer > div > .titleSection{
  padding-left: 0;
  display: flex;
  justify-content: space-between;
}