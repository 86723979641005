.ads{
  flex-direction: column;
  padding: 1rem 5rem;
  align-items: center;
}

.ads .title{
  margin: 1rem 0;
  margin-bottom: 3rem;
  text-align: center;
}

.ads__form{
  min-width: 300px;
  width: 60%;
  max-width: 800px;
}