.users{
  padding: 2em;
  max-width: 100%;
}

.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.users{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.users__user{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
}