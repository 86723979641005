header{
  background-color: var(--clr-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 15vh;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  padding: .5rem 1rem;
  -webkit-box-shadow: var(--bs-header);
  -moz-box-shadow: var(--bs-header);
  box-shadow: var(--bs-header);
  z-index: 2;
}

header .Logo{
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 70%;
  flex-wrap: wrap;
}

header h1 {
  /* text-shadow: 0px 0px 0px rgba(0, 0, 0, 1); */
  letter-spacing: 3.5px;
  font-size: var(--fs-Logo);

  font-weight: 600;

  /* color: black; */
  color: var(--clr-primary);
  /* -webkit-text-fill-color: var(--clr-primary); Will override color (regardless of order) */
  /* -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black; */
  height: max-content;
}

header .Logo a:hover{
  text-decoration: none;
}

.nav{
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.nav_signButtons{
  background-color: var(--clr-background);
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  z-index: 1;
}

.loginButton{
  border-radius: 2em;
  background-color: var(--clr-secondary);
  padding: .2em 1.5em;
  word-spacing: .2em;
  align-self: flex-end;
  margin-right: 2rem;
  border: 0;
  margin: .25rem 0;
  font-size: 1.2rem;
  cursor: pointer;
  border: 1px solid var(--clr-secondary);
  z-index: 1;
}

.loginButton:hover {
  border: 1px solid ;
}

.nav__user{
  display: flex;
  flex-direction: column;
  position: relative;
  user-select: none;
  z-index: 1;
}

.nav__user__icons{
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 1;
}

.nav__user__icons .material-icons{
  font-size: 36px;
}

.material-icons{
  text-decoration: none !important;
}

.nav__user__dropdown{
  background-color: var(--clr-primary);
  background-color: var(--clr-secondary);

  -webkit-box-shadow: var(--bs-normal);
  -moz-box-shadow: var(--bs-normal);
  box-shadow: var(--bs-normal);

  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: var(--br);

  width: 100%;
  min-width: 175px;
  min-height: 100%;
  right: 35%;
  top: 125%;

  opacity: 0;
  transition: .3s ease opacity;
  z-index: -1;
}

.nav__user__dropdown::after{
  content: '';
  width: 1rem;
  height: .7rem;
  background-color: var(--clr-primary);
  background-color: var(--clr-secondary);


  clip-path: polygon(50% 0%, 100% 100%, 0 100%);
  position: absolute;
  top: -.7rem;
  right: 1rem;
}

.nav__user__dropdown-active{
  opacity: 1;
  z-index: 20;
}

.nav__user__dropdown > .navigator__button{
  display: none;
  cursor: pointer;
  align-items: center;
  margin: .5rem 0;
  /* color: var(--clr-primary); */
}

.nav__user__dropdown-active > .navigator__button{
  display: flex;
}

.nav__user__dropdown > .navigator__button:hover{
  text-decoration: underline;
}

.navigator__button .material-icons{
  margin-right: 1rem;
}

.advert{
  background-color: var(--clr-secondary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: .5rem;
  background-color: var(--clr-secondary);
  padding: .5em 5em;
  /* height: 75px; */
  border: 2px solid black;
}

@media screen and (max-width: 1036px){
  header .Logo{
    width: 95%;
  }
  .nav{
    width: 95%;
  }
}

@media screen and (max-width: 474px){
  header .Logo{
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 600px){
  header{
    flex-direction: column;
  }

}

@media screen and (max-width: 450px){
  header h1 {
    font-weight: 400;
    -webkit-text-stroke-width: 1px;
  }

  .nav{
    width: 100%;
  }

  /* .loginButton{
    position: absolute;
    top: 1rem;
    right: 0rem;
  } */
}

@media screen and (max-width: 580px){
  header h1 {
    font-weight: 400;
    -webkit-text-stroke-width: 1px;
  }

  .nav{
    width: 100%;
  }
}