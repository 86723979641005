:root{
  --fw-light: 400;
  --fw-regular: 500;
  --fw-bold: 700;

  /* #0f3057 */
  /* #F9F9F9 */
  --clr-primary: #0f3057;
  /* --clr-fallback-Primary: #0f3057; */
  --clr-secondary: #e7e7de;
  --clr-background: #F9F9F9;
  --clr-error: #F20000;
  --clr-black: #000000;

  --clr-grey-Strong: #626262;

  --fs-Logo: 3rem;
  --fs-h1: 2.5rem;
  --fs-h2: 2rem;
  --fs-h3: 1.5rem;
  --fs-h4: 1.25rem;
  --fs-body: 1rem;
  --fs-small: .8rem;
  --fs-ultra-small: .8rem;

  --br: 1em;
  --br-card: .5rem;
  --br_input: 1em;

  --bs-header: 0px 3px 12px 0px rgba(0,0,0,0.25);
  --bs-normal: 0px 31px 60px -24px rgba(0,0,0,0.75);
  --bs-card: 0px 31px 60px -24px rgba(0,0,0,0.25);
  --bs-map: 10px 3px 14px 0px rgba(0, 0, 0, 0.226);
}

@media screen and (max-width: 935px) {
  :root{
    --fs-Logo: 4rem;
  }
  .titleSection{
    padding: 0 !important;
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 700px) {
  :root{
    --fs-Logo: 3rem;
  }
}

@media screen and (max-width: 580px) {
  :root{
    --fs-Logo: 2rem;
    --fs-h1: 2rem;
  }
}

/*-------General styles------*/

*{
  font-family: Roboto;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1{
  color: var(--clr-primary);
  font-size: 2rem;
}

a{
  color: black;
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}

.strong{
  color: var(--clr-primary);
}

section {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  z-index: 1;
  background-color: var(--clr-background);
  /* margin-top: 1rem; */
  padding: 1rem;
}

.buttonPrimary{
  cursor: pointer;
  background-color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  color: white;
  text-transform: uppercase;
  padding: .5em 2em;
  border-radius: .5em;
  min-width: 18rem;
}

.buttonPrimary.Secondary{
  background-color: var(--clr-background);
  color: var(--clr-primary);
}

.buttonPrimary.Third{
  border: 2px solid var(--clr-background);
}

.titleSection{
  width: 100%;
  padding-left: 12rem;
  color: var(--clr-black);
}

.titleSection h2{
  font-weight: 400;
  font-size: var(--fs-h1);
}

.arrow{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 0;
  border: 3px solid var(--clr-primary);
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
  z-index: 3;
}

.arrow::before, .arrow::after{
  content: '';
  position: absolute;
  top: -.55rem;
  left: -.3rem;
  border-radius: .3rem;
  width: 1.2rem;
  /* height: 4px; */
  border: 3px solid var(--clr-primary);
  transform: rotate(145deg);
}

.arrow::after{
  transform: rotate(35deg);
  top: auto;
  bottom: -.55rem;
}

#next{
  transform: rotate(180deg);
}

.closeButton{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  transition: .5s ease;
}

.closeButton:hover{
  transform: scale(1.1) rotate(1turn);
}

.closeButton::before, .closeButton::after{
  content: "";
  position: absolute;
  width: 25px;
  height: 4px;
  background-color: var(--clr-primary);
  border-radius: 4px;
  transform: rotate(45deg);
}

.closeButton::after{
  transform: rotate(-45deg);
}

/*-----------------Material Icons--------------*/

.material-icons{
  color: var(--clr-primary);
  user-select: none;
  cursor: pointer;
}

.material-icons:hover{
  text-decoration: none !important;
}
  
/*-------------Typography----------------*/

.dynamic_form__input__label {
  color: var(--clr-primary);
  font-weight: var(--fw-regular);
  font-size: var(--fs-h3);
}

.garage__label {
  color: var(--clr-primary);
  font-weight: var(--fw-bold);
}

.dynamic_form__input__input {
  font-size: var(--fs-h3);
}

.map__button.material-icons {
  color: var(--clr-background);
}

.map__address {
  color: var(--clr-background);
  font-size: var(--fs-small);
}

/*------------------GENERALS-----------------------*/

*::-webkit-scrollbar {
  width: .35em;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--clr-primary);
  border-radius: 1rem;
  outline: 1px solid slategrey;
}

.screen__options__buttons {
  position: absolute;
  top:.5em;
  height: 1em;
  width: 1em;
  font-size: var(--fs-h2);
  cursor: pointer;
}

@media screen and (min-width: 750px){
  .customed__form__container {
    margin: 0 auto;
    height: auto;
    width: max-content;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
  }

  .customed__form__container.form--visible {
    display: flex;
  }

  .customed__form__container > .GarageList {
    max-height: none;
    width: 100%;
  }

  .customed__form__container .garageContainer {
    width: 30%;
    min-width: 15rem;
  }
}

/*-------------------Custom React Sweet Alert Styles-----------------------------*/
.Swal__popup{
  min-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: unset;
  background-color: var(--clr-background-light, #f4f6ff);
}

.Swal__Content{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  position: relative;
}

.Swal__closeButton{
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.swal2-html-container{
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 800px;
}

.Swal__popup .titleSection {
  margin-top: 1.5em;
}