.dynamic_form__container, .dynamic_form__container form{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dynamic_form__submit_button {
  font-size: var(--fs-h4);
  align-self: center;
  padding: 0.75em 1.25em;
  color: white;
  border-radius: var(--br);
  border: none;
  background-color: var(--clr-primary);
  cursor: pointer;
  margin: 2rem 0;
}

.dynamic_form__submit_button[disabled] {
  opacity: .7;
  cursor: initial;
}

.buttonPrimary[disabled] {
  opacity: .7;
  cursor: initial;
}

.dynamic_form__input__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  position: relative;
}

.dynamic_form__input__container.List .dynamic_form__input{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.dynamic_form__input__container.List .dynamic_form__input .CheckBoxContainer{
  margin: .5rem;
}

.dynamic_form__input__container.List.woLabel .dynamic_form__input{
  justify-content: center;
  width: 100%;
  max-width: 38rem;
}

.dynamic_form__input__container.center{
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
}

.dynamic_form__input{
  position: relative;
  width: 100%;
}

.dynamic_form__input__input {
  border: none;
  border-bottom: 1px solid var(--clr-secondary);
  background-color: transparent;
  padding: .25em .20em;
  width: 100%;
  position: relative;
}

.dynamic_form__input__textArea {
  width: 100%;
  padding: .25em .20em;
  resize: none;
  height: 5em;
}

.dynamic_form__input__input.error{
  border-color: var(--clr-error);
}

.dynamic_form__input__withButton{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  min-width: 320px;
  width: 100%;
}

.dynamic_form__input__withButton button{
  padding: .5em;
  min-width: 8em;
}

.dynamic_form__container__actions{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.file.dynamic_form__input__input{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border-bottom: 0;
}

.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.chooseFileButton {
  background-color: var(--clr-primary);
  border: 0;
  color: white;
  cursor: pointer;
  font-size: var(--fs-body);
  font-weight: 700;
  padding: .5em;
  display: inline-block;
  min-width: 8em;
  text-align: center;
  border-radius: var(--br);
  /* outline: 1px dotted #000; */
  /* outline: -webkit-focus-ring-color auto 5px; */
}

.inputfile:focus + label,
.inputfile + label:hover {
  background-color: var(--clr-primary);
}

.filePreview{
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  padding-top: 3rem;
  justify-content: center;
  align-items: flex-start;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: -1;
  overflow-y: auto;
  overflow-x: hidden;
}

.filePreview.active{
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.PreviewContainer .closeButton{
  position: absolute;
  transform: scale(1.3);
  top: 0rem;
  right: 0;
}

/* .filePreview img{
  max-width: 60%;
  object-fit: contain;
} */
.fileContainer{
  min-width: 300px;
  width: 100%;
  max-height: 90vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.sliderContainer{
  min-width: 300px;
  width: 100%;
  max-height: 90vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.edit{
  cursor: pointer;
	width: 25px;
  height: 25px;
  transition: .3s ease;
}

/* Tooltip container */
.tooltip {
  position: absolute;
  display: block;
  right: -.2rem;
  top: calc(50% - .75rem);
  min-height: 1.5rem;
  /* justify-content: center;
  align-content: center; */
}

.tooltip .tooltiplabel {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--clr-primary);
  color: var(--clr-background);
  position: absolute;
  top: calc(50% - .75rem);
  font-weight: bolder;
}

.tooltip.error .tooltiplabel, .tooltip.error .tooltiptext{
  background-color: var(--clr-error);
}

/* Tooltip text */
.dynamic_form__input__container .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: var(--clr-primary);
  color: #fff;
  text-align: center;
  padding: 1em;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  min-width: 150px;
  bottom: 1rem;
  right: 0%;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.dynamic_form__input__input:focus + .tooltip .tooltiptext, .tooltip:hover .tooltiptext {
  visibility: visible;
}

/*----------------------------CheckBoxes-------------------------------------*/
.checkbox {
  --background: #fff;
  --border: var(--clr-primary);
  --border-hover: var(--clr-primary);
  --border-active: var(--clr-primary);
  --tick: #fff;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.checkbox + .checkbox {
  margin-top: .5em;
}
.checkbox > label{
  margin-left: .5em;
  cursor: pointer;
}
.checkbox input,
.checkbox svg {
  width: 21px;
  height: 21px;
  display: block;
}
.checkbox input {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  outline: none;
  background: var(--background);
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border-radius: 4px;
  transition: box-shadow 0.3s;
  box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));
}
.checkbox input:hover {
  --s: 2px;
  --b: var(--border-hover);
}
.checkbox input:checked {
  --b: var(--border-active);
}
.checkbox svg {
  pointer-events: none;
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--stroke, var(--border-active));
  position: absolute;
  top: 0;
  left: 0;
  width: 21px;
  height: 21px;
  transform: scale(var(--scale, 1)) translateZ(0);
}
/* .checkbox.path input:checked {
  --s: 2px;
  transition-delay: 0.4s;
}
.checkbox.path input:checked + svg {
  --a: 16.1 86.12;
  --o: 102.22;
}
.checkbox.path svg {
  stroke-dasharray: var(--a, 86.12);
  stroke-dashoffset: var(--o, 86.12);
  transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
} */
/* Code for adding the another animation 
<svg viewBox="0 0 21 21">
<path d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"></path>
</svg>
 */
.checkbox.bounce {
  --stroke: var(--tick);
}
.checkbox.bounce input:checked {
  --s: 11px;
}
.checkbox.bounce input:checked + svg {
  animation: bounce 0.4s linear forwards 0.2s;
}
.checkbox.bounce svg {
  --scale: 0;
}

@keyframes bounce {
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

/* Hide the browser's default checkbox */
.CheckBoxContainer{
  cursor: pointer;
}

.CheckBoxContainer > input {
	position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  border-radius: 12px;
	outline: 0;
}

/* Create a custom checkbox */
.CheckBoxContainer .checkmark {
  display: block;
  border: 2px solid var(--clr-primary);
  border-radius: 12px;
  text-align: center;
  color: var(--clr-primary);
  /* text-indent: 8px; */
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  letter-spacing: .3px;
  position: relative;
  padding: .5em 1em;
  height: max-content;
  background-color: #FFF;
  width: auto;
  transition: .3s ease;
  z-index: -1;
}

/* On mouse-over, add a grey background color */
.CheckBoxContainer:hover > input ~ .checkmark {
  background-color: #eee;
}

/* When the checkbox is checked, add an color background */
.CheckBoxContainer > input:checked ~ .checkmark {
  background-color: var(--clr-primary);
  color: white;
}

.CheckBoxContainer > span{
	user-select: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.Swal__popup{
  min-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: unset;
  background-color: rgba(0, 0, 0, 0);
}

.Swal__Content{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  position: relative;
}

.Swal__closeButton{
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.swal2-html-container{
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 800px;
}

/*------------------------------------Responsive-------------------------------------------*/

@media screen and (min-width: 750px){
  .dynamic_form__input__container {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: .5em;
    align-items: center;
    width: 100%;
  }
  
  .dynamic_form__input__label {
    margin-right: .5em;
  }

  .dynamic_form__input__withButton{
    width: 360px;
    position: relative;
    flex-wrap: nowrap;
    align-items: center;
  }

  .dynamic_form__input{
    width: 18em;
    min-width: 360px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
  }
  
  .dynamic_form__input__withButton .dynamic_form__input__input{
    width: 8em;
    min-width: 180px;
  }
  /* .dynamic_form__input__input {
  } */
}

/* .spinner {
  width: 5rem;
  height: 5rem;
  background-color: var(--clr-primary);

  margin: 0 auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% { 
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
  } 50% { 
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
  } 100% { 
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
} */

.spinner {
  margin: 50px auto;
  width: 5rem;
  height: 4rem;
  text-align: center;
  font-size: 1rem;
}

.spinner > div {
  background-color: var(--clr-primary);
  height: 100%;
  width: 6px;
  display: inline-block;
  margin: 0 .1rem;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
@keyframes ldio-ik6pzj2wupc {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-ik6pzj2wupc div {
  position: absolute;
  width: 120px;
  height: 120px;
  border: 5px solid var(--clr-primary);
  border-top-color: transparent;
  border-radius: 50%;
  animation: ldio-ik6pzj2wupc 1s linear infinite;
  top: 100px;
  left: 100px
}
.loadingio-spinner-rolling-w23e05eix9 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff;
}
.ldio-ik6pzj2wupc {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-ik6pzj2wupc div { box-sizing: content-box; }