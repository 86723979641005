.loading{
  width: 100vw;
  height: 100vh;
  background-color: var(--clr-primary);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading svg{
  width: 60%;
  height: auto;
  object-fit: contain;
  min-width: 300px;
  max-width: 350px;
  height: 80%;
}

.loading {
  color: var(--clr-background);
}