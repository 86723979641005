.landingSection{
  width: 100%;
  min-width: 300px;
  min-height: 100vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
  background-color: var(--clr-primary);
}

.landingSection .title{
  width: 20%;
  color: white;
  /* background-color: white; */
  text-align: center;
}

.landingSection .title *{
  /* color: var(--clr-primary); */
  color: white;
} 

.landingSection h2{
  width: 100%;
  color: var(--clr-primary);
  margin: 0;
  padding: 0;
}

.select.directoriesSelect{
  background-color: white;
  box-shadow: var(--bs-normal);
  min-width: 300px;
  width: 60%;
}

.landingSection footer{
  width: 100%;
}

.landingSection footer *{
  color: white;
}

/*------------------------------Custom Select-------------------------*/
.select{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  position: relative;
  min-height: 3rem;
  /* max-width: 350px; */
  --inputColor: rgb(53, 53, 53);
  --placeholderColor: rgb(92, 92, 92);
  --backgroundColor: white;
  --decorationColor: var(--clr-primary, black); 
}

.chosen-value, .value-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.chosen-value {
  font-weight: 600;
  letter-spacing: 4px;
  height: 3rem;
  font-size: 1.1rem;
  padding: 1rem;
  background-color: var(--backgroundColor);
  color: var(--inputColor);
  border: 3px solid transparent;
  border-bottom: 2px solid var(--clr-primary);
  border-radius: .2rem;
  transition: 0.3s ease-in-out;
}

.chosen-value::-webkit-input-placeholder {
  color: var(--placeholderColor);
}

.chosen-value:hover {
  background-color: rgb(201, 201, 201);
  cursor: pointer;
}
.chosen-value:hover::-webkit-input-placeholder {
  color: var(--placeholderColor);
}

.chosen-value:focus, .chosen-value.open {
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
  outline: 0;
  /* background-color: var(--clr-primary); */
  color:var(--inputColor);
}

.chosen-value:focus::-webkit-input-placeholder, .chosen-value.open::-webkit-input-placeholder {
  color: var(--placeholderColor);
}

.value-list {
  list-style: none;
  padding: 0;
  margin-top: 3rem;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  max-height: 0;
  transition: 0.3s ease-in-out;
  width: 100%;
}

.value-list.open {
  max-height: 320px;
  overflow: auto;
}

.value-list li {
  position: relative;
  height: 3rem;
  border-bottom: 2px solid transparent;
  background-color: #fafcfd;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  opacity: 1;
}

.value-list li a {
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.value-list li:hover {
  border-color: var(--clr-primary);
}
.value-list li.closed {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  opacity: 0;
}