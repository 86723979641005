.Ads__ad{
  display: flex;
  flex-wrap: nowrap;
}

.add__data{
  display: flex;
  flex-direction: column;
  width: 50%;
}

.add__Image{
  display: flex;
  justify-content: center;
  align-content: center;
  width: 40%;
  padding: 2rem;
}


.add__Image img {
  width: 80%;
  height: auto;
  object-fit: contain;

}