.login{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  background-color: var(--clr-primary);
}

.login > .ads {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 20%;
  min-width: 200px;
  height: auto;
  min-height: 200px;
  background-color: var(--clr-background);
  right: 1rem;
  top: 1rem;
  border-radius: 1rem;
}

.login__form{
  background-color: var(--clr-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 30rem;
  width: 35%;
  min-width: 300px;
  min-height: 550px;
  height: 100%;
  border-radius: 1.5rem;
  padding: 2rem;
}

.login__form > h1{
  text-align: center;
}

.login__form .dynamic_form__container{
  align-items: flex-start;
  justify-content: space-around;
  min-height: 320px;
}

.login__form .dynamic_form__input__container{
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.login__form .dynamic_form__input__container > div, .login__form .dynamic_form__input__container > div input{
  width: 100%;
}

.login__form small{
  margin-bottom: 1rem;
}

.login__form .RSS_icon{
  cursor: pointer;
}

.login__form .RSS_icon img{
  max-width: 2rem;
  max-height: 2rem;
}

.login__form .advice{
  text-align: center;
}

.login__form .adswithus {
  margin-top: 1rem;
  text-align: center;
}

.forgotButton{
  cursor: pointer;
  color: var(--clr-primary);
  margin: 1rem 0;
}

.forgotButton:hover{
  text-decoration: underline;
}